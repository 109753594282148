import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import LinearProgress from '@mui/material/LinearProgress'
import { HeaderButtons } from './HeaderButtons'
import { Navigation } from './Navigation'
import { useTranslation } from 'react-i18next'
import { usePageLoading } from '../../../stores/page-loading'
import { useLocation } from '@reach/router'

export const Header: React.FC = () => {
  const { i18n } = useTranslation()
  const isPageLoading = usePageLoading((state) => state.isLoading)
  const setIsPageLoading = usePageLoading((state) => state.setIsLoading)
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const toggleDrawer = (newValue: boolean) => {
    setIsOpen(newValue)
  }

  const showPageLoader = () => {
    setIsPageLoading(true)
  }

  useEffect(() => {
    if (isPageLoading) {
      setIsPageLoading(false)
    }
  }, [location.pathname, location.search])

  useEffect(() => {
    setIsPageLoading(false)
  }, [i18n.language])

  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    let isMounted = true
    const fn = () => {
      if (isMounted && typeof window !== 'undefined') {
        setScrollY(window.scrollY)
      }
    }
    document.addEventListener('scroll', fn)
    document.addEventListener('resize', fn)
    document.addEventListener('load', fn)
    return () => {
      isMounted = false
      document.removeEventListener('scroll', fn)
      document.removeEventListener('resize', fn)
      document.removeEventListener('load', fn)
    }
  }, [])

  useEffect(() => {
    const parseUTMParams = (url: string) => {
      const getUTMParams = (part: string) => {
        const utmParams: { [key: string]: string } = {}
        const regex = /[?&#](utm_[^=]+)=([^&#]*)/g
        let match
        while ((match = regex.exec(part)) !== null) {
          utmParams[decodeURIComponent(match[1])] = decodeURIComponent(match[2])
        }
        return utmParams
      }

      const [baseUrl, hashFragment] = url.split('#')

      const queryParams = getUTMParams(baseUrl)
      const hashParams = hashFragment ? getUTMParams(hashFragment) : {}

      return { ...queryParams, ...hashParams }
    }

    const utmParams = parseUTMParams(location.href)

    if (Object.keys(utmParams).length) {
      localStorage.setItem('utmObject', JSON.stringify(utmParams))
    }
  }, [])

  return (
    <AppBar
      component={'header'}
      sx={{
        bgcolor: { xs: 'transparent', sm: '#ffffff' },
        position: 'sticky',
        top: 0,
        zIndex: '1201',
        transform: { xs: 'none', sm: scrollY === 0 ? 'none' : 'translateY(-30px)' },
        transition: 'all .2s linear',
        boxShadow: scrollY && !isOpen ? '0px 8px 24px 0px #0B4E831F' : 'none',
      }}
    >
      <HeaderButtons hideonMobile onChangeLanguage={showPageLoader} scrollY={scrollY} />
      <Navigation onChangeLanguage={showPageLoader} toggleDrawer={toggleDrawer} />
      <Box minHeight={'0.25em'} sx={{ position: 'absolute', bottom: '0', width: '100%' }}>
        {isPageLoading && <LinearProgress />}
      </Box>
    </AppBar>
  )
}
