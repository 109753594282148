/* eslint-disable react/hook-use-state */
import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import { ArticleRounded, AutoAwesomeMotionRounded, HomeWorkRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Link as NavLink } from '../../Link'
import { pagelist } from '../../../../pagelist.data'
import { usePageLoading } from '../../../stores/page-loading'
import { useLocation } from '@reach/router'
import { EducationalCenter } from './EducationalCenter'
import { AboutUs } from './AboutUs'
import { resources } from '../../../constants/langConstants'
import { montserratFamily } from '../../../constants/themeVars'

//isLink
export type NavOption = {
  isLink: boolean
  title?: keyof typeof resources.en.translation
  to?: string
  component: JSX.Element
  external?: boolean
  navigateWithoutCache?: boolean
  onClick?: () => void
}

export const NavigationList: React.FC<{ hideonMobile?: boolean; onLinkClick?: () => void }> = ({
  hideonMobile,
  onLinkClick,
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const setIsPageLoading = usePageLoading((state) => state.setIsLoading)

  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    let isMounted = true
    const fn = () => {
      if (isMounted && typeof window !== 'undefined') {
        setScrollY(window.scrollY)
      }
    }
    document.addEventListener('scroll', fn)
    document.addEventListener('resize', fn)
    document.addEventListener('load', fn)
    return () => {
      isMounted = false
      document.removeEventListener('scroll', fn)
      document.removeEventListener('resize', fn)
      document.removeEventListener('load', fn)
    }
  }, [])

  const handleLinkClick = (to: string) => {
    if (!location.pathname.includes(to)) {
      setIsPageLoading(true)
    }

    onLinkClick && onLinkClick()
  }

  const navOptions: NavOption[] = [
    {
      isLink: false,
      component: <EducationalCenter onLinkClick={onLinkClick} />,
    },
    {
      isLink: true,
      title: 'Header.AllPrograms',
      to: `/${i18n.language}${pagelist.programs.path}`,
      navigateWithoutCache: true,
      component: (
        <AutoAwesomeMotionRounded
          sx={{
            color: 'primary.states.outlinedBorder',
            width: '1.25em',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        />
      ),
      external: false,
    },
    // {
    //   isLink: true,
    //   title: 'Header.Residences',
    //   to: `/${i18n.language}${pagelist.residences.path}`,
    //   component: (
    //     <HomeWorkRounded
    //       sx={{
    //         color: 'primary.states.outlinedBorder',
    //         width: '1.25em',
    //         transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
    //       }}
    //     />
    //   ),
    //   external: false,
    // },
    {
      isLink: true,
      title: 'Header.Univesities',
      to: `/${i18n.language}${pagelist.universities.path}`,
      navigateWithoutCache: true,
      component: (
        <HomeWorkRounded
          sx={{
            color: 'primary.states.outlinedBorder',
            width: '1.25em',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        />
      ),
      external: false,
    },
    {
      isLink: true,
      title: 'Header.Media',
      to: `/${i18n.language}${pagelist.media.path}`,
      component: (
        <ArticleRounded
          sx={{
            color: 'primary.states.outlinedBorder',
            width: '1.25em',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
          }}
        />
      ),
      external: false,
    },
    {
      isLink: false,
      component: <AboutUs onLinkClick={onLinkClick} />,
    },
  ]

  const renderLink = (navLink: NavOption) => {
    if (navLink.external) {
      return (
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            padding: '0',
            '&:hover': { backgroundColor: 'rgba(11, 78, 131, 0.04)' },
            '&:hover svg': { color: 'inherit' },
            borderRadius: '12px',
          }}
        >
          <Link
            href={navLink.to}
            sx={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              columnGap: '.5em',
              py: '.5rem',
              px: '.6875rem',
            }}
          >
            {navLink.component}
            <Typography component={'span'} variant={'subtitle2'} sx={{ color: theme.palette.text.primary }}>
              {navLink.title && t(navLink.title)}
            </Typography>
          </Link>
        </Box>
      )
    }
    return (
      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          '&:hover': { backgroundColor: 'rgba(11, 78, 131, 0.04)' },
          '&:hover svg': { color: 'inherit' },
          borderRadius: '12px',
          p: 0,
        }}
      >
        <NavLink
          to={navLink.to as string}
          withoutCache={navLink.navigateWithoutCache}
          onClick={handleLinkClick}
          sx={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            columnGap: '.5em',
            py: '.5rem',
            px: '.6875rem',
          }}
        >
          {navLink.component}
          <Typography component={'span'} variant={'subtitle2'} sx={{ color: theme.palette.text.primary }}>
            {navLink.title && t(navLink.title)}
          </Typography>
        </NavLink>
      </Box>
    )
  }

  return (
    <List
      sx={{
        display: hideonMobile ? { xs: 'none', sm: 'flex' } : { xs: 'flex', sm: 'none' },
        flexDirection: { xs: 'column', sm: 'row' },
        rowGap: '.75em',
        columnGap: '1em',
        padding: { xs: '0 1em', sm: '0' },
        transform: {
          sm: scrollY >= 600 ? 'translateX(0)' : i18n.language === 'en' ? 'translateX(85px)' : 'translateX(116px)',
        },
        transition: scrollY !== 0 ? 'all .2s linear' : '',
      }}
    >
      {navOptions.map((item, index) => (
        <Box key={item.to} sx={{ display: 'flex', flexDirection: 'column', rowGap: '.75em' }}>
          {item.isLink ? renderLink(item) : item.component}
          {!hideonMobile && index !== navOptions.length - 1 && <Divider />}
        </Box>
      ))}
      {hideonMobile && (
        <Link
          href={`${process.env.GATSBY_DASHBOARD_URL}/signin`}
          sx={{
            alignItems: 'center',
            height: '1.75rem',
            borderRadius: theme.shape.borderRadius,
            width: { xs: '100%', sm: 'auto' },
            textDecoration: 'none',
            justifyContent: { xs: 'center', sm: 'flex-start' },
            color: theme.palette.primary.contrastText,
            bgcolor: theme.palette.primary.main,
            paddingX: '.75rem',
            alignSelf: 'center',
            transition:
              'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, max-width .3s ease-in-out;',
            '&:hover': { bgcolor: '#0E5E9C' },
            maxWidth: 'fit-content',
            // maxWidth: scrollY >= 600 ? (i18n.language === 'en' ? '61px' : '91.36px') : '0px',
          }}
        >
          <Typography
            component={'span'}
            sx={{
              fontFamily: montserratFamily,
              fontSize: '.75rem',
              lineHeight: '1.5rem',
              fontWeight: 600,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t('Header.Login')}
          </Typography>
        </Link>
      )}
    </List>
  )
}
