import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { resources } from '../constants/langConstants'
/* TODO: provide fallbackLanguage from config */
const fallbackLanguage = 'en'
i18next.use(LanguageDetector).use(initReactI18next).init({
  debug: !!process.env.DEBUGI18N,
  fallbackLng: fallbackLanguage,
  resources: resources,
})

export default i18next
