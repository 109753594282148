import * as React from 'react'
import Drawer from '@mui/material/Drawer'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { ArrowBackRounded, KeyboardArrowRightRounded } from '@mui/icons-material'
import { palette } from '../../../constants/themeVars'

interface Props {
  content: JSX.Element
  buttonText: string
  icon: JSX.Element
  onLinkClickRef?: React.MutableRefObject<VoidFunction | undefined>
}

export const NavigationDrawer: React.FC<Props> = ({ content, buttonText, icon, onLinkClickRef }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const theme = useTheme()
  const { t } = useTranslation()

  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setIsOpen((prev) => !prev)
  }

  React.useEffect(() => {
    if (onLinkClickRef) {
      onLinkClickRef.current = () => setIsOpen(false)
    }
  }, [])

  return (
    <>
      <Button
        size='large'
        sx={{
          display: { xs: 'flex', sm: 'none' },
          justifyContent: 'space-between',
          py: '.5rem',
          px: '.6875rem',
          '&:hover svg': { color: 'inherit' },
        }}
        onClick={toggleDrawer()}
      >
        <Box sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', columnGap: '.5em' }}>
          {icon}
          <Typography variant={'subtitle2'} sx={{ color: theme.palette.text.primary }}>
            {t(buttonText)}
          </Typography>
        </Box>
        <KeyboardArrowRightRounded
          sx={{ width: '1.25rem', height: '1.25rem', display: { xs: 'flex', sm: 'none' }, color: 'text.primary' }}
        />
      </Button>
      <Drawer
        keepMounted
        // disablePortal
        anchor={'right'}
        open={isOpen}
        onClose={toggleDrawer()}
        sx={{ position: 'unset' }}
        hideBackdrop
        PaperProps={{ sx: { boxShadow: 'none', width: '100%', justifyContent: 'space-between' } }}
        disableRestoreFocus
      >
        <Box
          sx={{
            height: 'calc(100% - 4.5em)',
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '0 0 1em 0',
            position: 'absolute',
            width: '100%',
            top: '4.5rem',
          }}
          role='presentation'
        >
          <Stack sx={{ gap: '.75em', padding: '1em 1em' }}>
            <Button
              size='small'
              sx={{
                display: { xs: 'flex', sm: 'flex' },
                justifyContent: 'space-between',
                padding: '.375em .75em',
                '&:hover svg': { color: 'inherit' },
              }}
              onClick={toggleDrawer()}
            >
              <Box sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', columnGap: '.5em' }}>
                <ArrowBackRounded sx={{ width: '1.125rem', height: '1.125rem', display: { xs: 'flex', sm: 'none' } }} />
                <Typography variant={'subtitle2'} sx={{ color: palette.primary?.main }} fontWeight={600} fontSize={'.75rem'}>
                  {t('Button.BackButton')}
                </Typography>
              </Box>
            </Button>
            <Divider />
            {content}
          </Stack>
        </Box>
      </Drawer>
    </>
  )
}
