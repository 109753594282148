import React, { useRef, useState } from 'react'
import { NavigationDrawer } from './NavigationDrawer'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { InfoRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { pagelist } from '../../../../pagelist.data'
import { Link } from 'gatsby'

export const AboutUs: React.FC<{ onLinkClick?: () => void }> = ({ onLinkClick }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(Boolean(anchorEl))
  const onLinkClickRef = useRef<VoidFunction>()
  const lang = i18n.language

  const data = [
    {
      to: `/${lang}${pagelist.aboutUsAboutGostudy.path}`,
      title: t('AboutUs.Item1.Title'),
      description: t('AboutUs.Item1.Description'),
    },
    {
      to: `/${lang}/media/knowledge/education/course-methodology`,
      title: t('AboutUs.Item2.Title'),
      description: t('AboutUs.Item2.Description'),
    },
    {
      to: `/${lang}${pagelist.aboutUsAboutReviews.path}`,
      title: t('AboutUs.Item3.Title'),
      description: t('AboutUs.Item3.Description'),
    },
    {
      to: `/${lang}${pagelist.aboutUsOffices.path}`,
      title: t('AboutUs.Item4.Title'),
      description: t('AboutUs.Item4.Description'),
    },
    {
      to: `/${lang}${pagelist.aboutUsAboutForPartners.path}`,
      title: t('AboutUs.Item5.Title'),
      description: t('AboutUs.Item5.Description'),
    },
  ]

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => !prev)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const contentMobile = () => (
    <Stack gap={'.75em'}>
      {data.map((item) => {
        return (
          <Link
            key={item.title}
            to={item.to}
            style={{ color: 'inherit', textDecoration: 'none' }}
            onClick={() => {
              if (onLinkClickRef.current) {
                onLinkClickRef.current()
              }
              if (onLinkClick) {
                onLinkClick()
              }
            }}
          >
            <Stack padding={'0 0 .5em'} gap={'.75em'}>
              <Stack px={'1em'}>
                <Typography variant='body1'>{item.title}</Typography>
                <Typography variant='caption' color={'text.secondary'}>
                  {item.description}
                </Typography>
              </Stack>
            </Stack>
          </Link>
        )
      })}
    </Stack>
  )

  const content = () => (
    <Stack>
      {data.map((item) => (
        <Link key={item.title} to={item.to} style={{ color: 'inherit', textDecoration: 'none' }}>
          <MenuItem key={item.title}>
            <Stack padding={'0 0 .5em'} gap={'.5em'}>
              <Stack px={'1em'}>
                <Typography variant='body1'>{item.title}</Typography>
                <Typography variant='caption' color={'text.secondary'}>
                  {item.description}
                </Typography>
              </Stack>
            </Stack>
          </MenuItem>
        </Link>
      ))}
    </Stack>
  )

  return (
    <>
      <Button
        size='large'
        sx={{
          display: { xs: 'none', sm: 'flex' },
          justifyContent: 'space-between',
          '&:hover svg': { color: 'inherit' },
        }}
        onClick={handleClick}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <Box sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', columnGap: '.5em' }}>
          <InfoRounded
            sx={{
              color: 'primary.states.outlinedBorder',
              width: '1.25em',
              transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
            }}
          />
          <Typography variant={'subtitle2'} component={'span'} sx={{ color: theme.palette.text.primary }}>
            {t('Header.AboutUs')}
          </Typography>
        </Box>
        <Menu
          keepMounted
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .MuiList-root': { py: '1em' },
            '& .MuiPaper-root': {
              marginTop: '1.5rem',
            },
          }}
        >
          {content()}
        </Menu>
        {open ? (
          <KeyboardArrowUpRounded
            sx={{
              width: '1.25rem',
              height: '1.25rem',
              display: { xs: 'none', sm: 'flex' },
              color: 'text.primary',
              marginLeft: '.375rem',
            }}
          />
        ) : (
          <KeyboardArrowDownRounded
            sx={{
              width: '1.25rem',
              height: '1.25rem',
              display: { xs: 'none', sm: 'flex' },
              color: 'text.primary',
              marginLeft: '.375rem',
            }}
          />
        )}
      </Button>
      <NavigationDrawer
        onLinkClickRef={onLinkClickRef}
        buttonText='Header.AboutUs'
        content={contentMobile()}
        icon={
          <InfoRounded
            sx={{
              color: 'primary.states.outlinedBorder',
              width: '1.25em',
              transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
            }}
          />
        }
      />
    </>
  )
}
