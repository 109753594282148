import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '@mui/material'

export const Terms: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Stack
      sx={{
        // flexDirection: { xs: 'column', sm: 'row' },
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        variant={'subtitle2'}
        color={theme.palette.text.secondary}
        textAlign={'center'}
        sx={{ paddingBottom: '12px' }}
      >
        For CIS inquires please visit{' '}
        <Link
          href={'https://www.gostudy.cz'}
          sx={{
            display: 'inline',
            columnGap: '.625em',
            textDecoration: 'none',
            color: 'text.primary',
            transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': { color: 'primary.main' },
          }}
          target='_blank'
        >
          <Typography component={'span'} variant={'subtitle2'} fontSize={'14px'}>
            gostudy.cz
          </Typography>
        </Link>
      </Typography>
      <Typography variant={'subtitle2'} color={theme.palette.text.secondary} textAlign={'center'}>
        2024, GSA Education Support Services LLC. All Rights Reserved.
      </Typography>
      {/* <Stack sx={{ flexDirection: 'row', columnGap: '1.5em' }}>
        <Link href='#'>
          <Typography variant={'subtitle2'} color={theme.palette.text.primary}>
            {t('Privacy Policy')}
          </Typography>
        </Link>
        <Link href='#'>
          <Typography variant={'subtitle2'} color={theme.palette.text.primary}>
            {t('Terms & Conditions')}
          </Typography>
        </Link>
      </Stack> */}
    </Stack>
  )
}
