import { create } from 'zustand'

export type Pagination = {
  currentClientPage: number
  isInitLoad: boolean
  setIsInitLoad: (newLoadState: boolean) => void
  setCurrentClientPage: (currentPage: number) => void
}

export const useClientPagination = create<Pagination>((set) => ({
  currentClientPage: 0,
  isInitLoad: false,
  setIsInitLoad: (newLoadState: boolean) => set({ isInitLoad: newLoadState }),
  setCurrentClientPage: (currentPage: number) => set({ currentClientPage: currentPage }),
}))
