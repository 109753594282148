import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { LangSwitcher } from './LangSwitcher'
import { getTestHref } from '../../../helpers/URLSearchParamsHelper'
import { pagelist } from '../../../../pagelist.data'

interface Props {
  hideonMobile?: boolean
  onChangeLanguage: VoidFunction
  scrollY?: number
}

export const HeaderButtons: React.FC<Props> = ({ hideonMobile, onChangeLanguage, scrollY }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const [testHref, setTestHref] = useState(`/${i18n.language}${pagelist.academy.path}`)
  useEffect(() => {
    const newHref = getTestHref(i18n.language, pagelist.academy.path)
    if (newHref) {
      setTestHref(newHref)
    }
  }, [])

  const commonButtonStyles = {
    display: hideonMobile ? { xs: 'none', sm: 'flex' } : { xs: 'flex', sm: 'none' },
    alignItems: 'center',
    height: { xs: '2.625em', sm: '1.875em' },
    borderRadius: theme.shape.borderRadius,
    width: { xs: '100%', sm: 'auto' },
    textDecoration: 'none',
  }

  return (
    <Box
      sx={{
        display: { xs: hideonMobile ? 'none' : 'flex', sm: 'flex' },
        bgcolor: { xs: 'transparent', sm: theme.palette.primary.main },
        height: { xs: 'auto', sm: '1.875em' },
      }}
    >
      <Container
        maxWidth={'md'}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          rowGap: '1em',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {(scrollY === undefined || scrollY === 0) && (
          <LangSwitcher hideonMobile={!!hideonMobile} onChangeLanguage={onChangeLanguage} />
        )}
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          rowGap={'1em'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          gap={'.5em'}
          width={'100%'}
        >
          {/* <Button
            size='large'
            sx={{
              padding: { xs: '0', sm: '.375em .75em' },
              display: hideonMobile ? { xs: 'none', sm: 'flex' } : { xs: 'flex', sm: 'none' },
            }}
            fullWidth={!hideonMobile}
          >
            <Link
              href={`tel:${phoneNumber}`}
              sx={{
                ...commonButtonStyles,
                justifyContent: { xs: 'center', sm: 'flex-start' },
                color: { xs: theme.palette.primary.main, sm: theme.palette.primary.contrastText },
                columnGap: '.5em',
                border: { xs: '1px solid primary.states.outlinedBorder', sm: 'none' },
              }}
            >
              <PhoneRounded sx={{ width: '1em' }} />
              <Typography sx={{ typography: { sm: 'subtitle2', xs: 'subtitle1' } }}>{phoneNumber}</Typography>
            </Link>
          </Button> */}
          <Button
            size='large'
            sx={{
              display: hideonMobile ? { xs: 'none', sm: 'flex' } : { xs: 'flex', sm: 'none' },
            }}
            fullWidth={!hideonMobile}
          >
            <Link
              href={testHref}
              // onClick={(e) => {
              //   e.preventDefault()
              //   navigateToTest(i18n.language)
              // }}
              sx={{
                ...commonButtonStyles,
                justifyContent: { xs: 'center', sm: 'flex-start' },
                color: theme.palette.primary.contrastText,
                border: { xs: '1px solid rgba(237,108, 2, 0.5)', sm: theme.palette.primary.main },
                bgcolor: { xs: '#FFFFFF', sm: theme.palette.primary.main },
              }}
            >
              <Typography sx={{ typography: { sm: 'subtitle2', xs: 'subtitle1' }, color: '#FF7707' }}>
                {t('Header.TestButton')}
              </Typography>
            </Link>
          </Button>
          <Button
            size='large'
            sx={{
              display: hideonMobile ? { xs: 'none', sm: 'flex' } : { xs: 'flex', sm: 'none' },
            }}
            fullWidth={!hideonMobile}
          >
            <Link
              href={`${process.env.GATSBY_DASHBOARD_URL}/signin`}
              sx={{
                ...commonButtonStyles,
                justifyContent: { xs: 'center', sm: 'flex-start' },
                color: theme.palette.primary.contrastText,
                bgcolor: theme.palette.primary.main,
              }}
            >
              <Typography sx={{ typography: { sm: 'subtitle2', xs: 'subtitle1' } }}>{t('Header.Cabinet')}</Typography>
            </Link>
          </Button>
        </Box>
      </Container>
    </Box>
  )
}
