export interface PageListItem {
  path: string
  template: string
  matchPath?: string
  defaultContext?: object
  uniqGen?: boolean
}
export const pagelist: { [key: string]: PageListItem } = {
  // residence: { path: '/residences/__slug__', template: 'residence', matchPath: '/residences/:slug' },
  // residences: { path: '/residences', template: 'residences' },
  programs: { path: '/catalog/programs', template: 'programs', uniqGen: true },
  // programsFirst: { path: '/catalog/programs', template: 'programs' },
  program: { path: '/catalog/program', template: 'program', uniqGen: true },
  // services: { path: '/catalog/services', template: 'services' },
  // servicesFirst: { path: '/catalog/services', template: 'services' },
  // service: { path: '/catalog/service/__id__', template: 'service', matchPath: '/catalog/service/:id' },
  universities: { path: '/universities', template: 'universities', uniqGen: true },
  // universitiesFirst: { path: '/universities', template: 'universities' },
  university: { path: '/university', template: 'university', uniqGen: true },
  mainPage: { path: '/', template: 'main' },
  media: { path: '/media', template: 'media', defaultContext: { currentCategory: { slug: '*' } }, uniqGen: true },
  typeform: { path: '/applicant-test', template: 'typeform' },

  /* landings */
  // residencesLandingPage: { path: '/residences-landing', template: 'residences-landing' },
  czechRepublicHigherEducation: {
    path: '/czech-republic-higher-education',
    template: 'czech-republic-higher-education',
  },
  czechRepublicStudyOnline: {
    path: '/czech-republic-study-online',
    template: 'czech-republic-study-online',
  },
  czechRepublicDoctors: {
    path: '/czech-republic-doctors',
    template: 'czech-republic-doctors',
  },
  czechRepublicHoliday: {
    path: '/czech-republic-holiday',
    template: 'czech-republic-holiday',
  },
  austriaHigherEducation: {
    path: '/austria-higher-education',
    template: 'austria-higher-education',
  },
  austriaPersonalAssistant: {
    path: '/austria-personal-assistant',
    template: 'austria-personal-assistant',
  },
  // aboutUsAboutGostudy: {
  //   path: '/about-us-about-gostudy',
  //   template: 'about-us-about-gostudy',
  // },
  aboutUsAboutGostudy: {
    path: '/about-us-about-gostudy',
    template: 'about-us-new',
  },
  czechLanguageVisa: {
    path: '/courses-for-visa-extension-in-the-czech-republic',
    template: 'czech-language-visa',
  },
  aboutUsOffices: {
    path: '/offices',
    template: 'about-us-offices',
  },
  aboutUsAboutReviews: {
    path: '/about-us-reviews',
    template: 'about-us-reviews',
  },
  aboutUsAboutForPartners: {
    path: '/about-us-for-partners',
    template: 'about-us-for-partners',
  },
  // czechLanguage: {
  //   path: '/czech-language',
  //   template: 'czech-language',
  // },
  academy: {
    path: '/academy',
    template: 'academy',
  },
  czechInEnglish: {
    path: '/admission-to-the-czech-republic-in-english',
    template: 'czech-in-english',
  },
  universityProgram: {
    path: '/pan-european-university-study-program',
    template: 'university-program',
  },
}
export default Object.values(pagelist)
