import * as Localization from '@mui/material/locale'
import translationEn from '../locales/en.json'
// import translationRu from '../locales/ru.json'
// import translationUk from '../locales/uk.json'
import translationVi from '../locales/vi.json'
// import translationHi from '../locales/hi.json'
import * as LocalizationX from '@mui/x-date-pickers-pro'

type LanguagesFiles = typeof translationEn
type languages = 'English' | 'Tiếng Việt'
// | 'Русский' | 'Українська' | 'हिंदी'
export type languagesI18n = 'vi' | 'en'
// | 'ru' | 'uk' | 'hi'
type resourcesType = Record<languagesI18n, { translation: LanguagesFiles }>
type i18ntoi10nType = Record<languagesI18n, { i10n: Localization.Localization }>
/* eslint-disable-next-line */
type i18ntoi10nXType = Record<languagesI18n, { i10nX: any }>

export interface IMuiLanguages {
  code: languagesI18n
  label: languages
  suggested?: boolean
}

interface ILangsData {
  i18n: languagesI18n
  i10n: Localization.Localization
  /* eslint-disable-next-line */
  i10nX: any
  label: languages
  translation: LanguagesFiles
}
const langsData: ILangsData[] = [
  { i18n: 'vi', i10n: Localization.viVN, i10nX: LocalizationX.viVN, label: 'Tiếng Việt', translation: translationVi },
  { i18n: 'en', i10n: Localization.enUS, i10nX: LocalizationX.enUS, label: 'English', translation: translationEn },
  // { i18n: 'ru', i10n: Localization.ruRU, i10nX: LocalizationX.ruRU, label: 'Русский', translation: translationRu },
  // { i18n: 'uk', i10n: Localization.ukUA, i10nX: LocalizationX.ukUA, label: 'Українська', translation: translationUk },
  // { i18n: 'hi', i10n: Localization.viVN, i10nX: LocalizationX.viVN, label: 'हिंदी', translation: translationHi },
]

export const resources: resourcesType = langsData.reduce((acc, { i18n, translation }) => {
  acc[i18n] = { translation }

  return acc
}, {} as resourcesType)

export const i18ntoi10n = langsData.reduce((acc, { i18n, i10n }) => {
  acc[i18n] = { i10n }

  return acc
}, {} as i18ntoi10nType)

export const i18ntoi10nX = langsData.reduce((acc, { i18n, i10nX }) => {
  acc[i18n] = { i10nX }

  return acc
}, {} as i18ntoi10nXType)

export const langs: IMuiLanguages[] = langsData.map((value) => ({ code: value.i18n, label: value.label }))
