import { create } from 'zustand'

export type PageLoading = {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

export const usePageLoading = create<PageLoading>((set) => ({
  isLoading: false,
  setIsLoading: (isLoading: boolean) => set({ isLoading }),
}))
