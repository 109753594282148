import React from 'react'
import { useTheme } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Logo from '../../../images/svg/mainLogoText.svg'
import { Link as NavLink } from 'gatsby'
import { HeaderDrawer } from './Drawer'
import { NavigationList } from './NavigationList'
import { useTranslation } from 'react-i18next'
import { usePageLoading } from '../../../stores/page-loading'
import { useLocation } from '@reach/router'

interface Props {
  onChangeLanguage: VoidFunction
  toggleDrawer: (newValue: boolean) => void
}

export const Navigation: React.FC<Props> = ({ onChangeLanguage, toggleDrawer }) => {
  const theme = useTheme()
  const location = useLocation()
  const { i18n } = useTranslation()
  const setIsPageLoading = usePageLoading((state) => state.setIsLoading)

  return (
    <Container
      maxWidth={'md'}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: { xs: '4.5em', sm: '3.75em' },
        bgcolor: theme.palette.background.paper,
        overflow: { sm: 'hidden' },
      }}
    >
      <NavLink
        to={`/${i18n.language}`}
        style={{ textDecoration: 'none', height: '1.25rem' }}
        onClick={() => {
          if (location && location.pathname !== `/${i18n.language}`) setIsPageLoading(true)
        }}
      >
        <Logo />
      </NavLink>
      <NavigationList hideonMobile />
      <HeaderDrawer onChangeLanguage={onChangeLanguage} toggleDrawer={toggleDrawer} />
    </Container>
  )
}
