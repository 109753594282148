import { create } from 'zustand'
import { languagesI18n } from '../constants/langConstants'
import { pagelist } from '../../pagelist.data'

type PagePaths = (typeof pagelist)[keyof typeof pagelist]['path']

type PathLanguageMap = {
  [K in PagePaths]: Array<languagesI18n>
}

export type LocalizationStore = {
  pages: PathLanguageMap
}

export const useLocalizationStore = create<LocalizationStore>()(() => ({
  pages: { [pagelist['universityProgram']['path']]: ['en'] },
}))
