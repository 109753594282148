import queryString from 'query-string'
import { pagelist } from '../../pagelist.data'

export const checkParamsEquality = (firstParams: URLSearchParams, secondParams: URLSearchParams) => {
  const arrayFromFirstParams = Array.from(firstParams)
  const arrayFromSecondParams = Array.from(secondParams)
  arrayFromFirstParams.sort()
  arrayFromSecondParams.sort()
  return JSON.stringify(arrayFromFirstParams) === JSON.stringify(arrayFromSecondParams)
}

export const formatQuery = (query: { [key: string]: string }, page?: string) => {
  const formatedQuery: { [key: string]: string | string[] } = {}
  for (const [key, value] of Object.entries(query)) {
    if (value.includes(', ')) {
      formatedQuery[key] = value.split(', ')
      continue
    }
    formatedQuery[key] = value
  }

  if (page) {
    formatedQuery['page'] = page.toString()
  }
  return formatedQuery
}

export const getData = async (route: string, language: string, important = false) => {
  const response = await fetch(`${process.env.GATSBY_API_URL}${route}`, {
    method: 'GET',
    mode: 'no-cors', // prevent sending 'OPTIONS' request first
    headers: new Headers({
      'Accept-Language': language,
    }),
  })
  if (important) {
    if (!response.ok) {
      throw new Error('Request failed')
    }
  }

  return await response.json()
}

export const formatParamsForDashboard = (
  product:
    | { program: { tariffId: string; periodId: string; paymentTypeId: string } }
    | { residence: { accommodationId: string; bookingPeriodId: string } }
    | { service: { id: string } }
) => {
  const base64String = window.btoa(JSON.stringify(product))
  const sp = new URLSearchParams({
    addtocart: base64String,
  })
  const utms = localStorage.getItem('utmObject')
  if (utms) {
    for (const [key, value] of Object.entries(JSON.parse(utms))) {
      sp.set(key, value as string)
    }
  }

  return sp.toString()
}

export const getTestHref = (lang: string, path?: string) => {
  if (typeof window !== 'undefined' && window.location) {
    let newHref = `${window.location.origin}/${lang}${path ?? pagelist.typeform.path}`
    const utmObject = localStorage.getItem('utmObject')
    if (utmObject) {
      newHref = `${newHref}?${queryString.stringify(JSON.parse(utmObject))}`
    }
    return newHref
  }
}

export const navigateToTest = (lang: string) => {
  if (typeof window !== 'undefined') {
    const href = getTestHref(lang)
    if (href) {
      window.location.href = href
    }
  }
}

export const navigateWithoutCache = (pathname: string) => {
  if (typeof window !== 'undefined') {
    const newHref = `${window.location.origin}${pathname}`
    window.location.href = newHref
  }
}
