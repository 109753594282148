import * as React from 'react'
import i18next from './src/config/i18next'
import type { GatsbyBrowser } from 'gatsby'
import theme from './src/config/theme'
import { I18nextProvider } from 'react-i18next'
import { languagesI18n } from './src/constants/langConstants'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LicenseInfo } from '@mui/x-license-pro'
import { Header } from './src/components/layout/Header/Header'
import { Footer } from './src/components/layout/Footer/Footer'
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form'
import 'dayjs/locale/en'
import 'dayjs/locale/ru'
import 'dayjs/locale/uk'
import RootElement from './src/components/RootElement'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CookieNotice } from 'gatsby-cookie-notice'
import './index.css'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  LicenseInfo.setLicenseKey(
    '1a83f756547309776c3cdf637f6a4a38Tz02MDMxMCxFPTE3MDg1MzQzMjI0NzMsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
  )
  const context = props.pageContext as MyContext
  if (context.language !== i18next.language) i18next.changeLanguage(context.language)
  return (
    <>
      <I18nextProvider i18n={i18next}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18next.language}>
          <ThemeProvider theme={theme(i18next.language as languagesI18n)}>
            <HubspotProvider>
              <CssBaseline />
              <Header />
              <RootElement>
                <React.Fragment>{element}</React.Fragment>
              </RootElement>
              <Footer />
              <CookieNotice
                personalizeButtonClasses={'my-btn-classes'}
                personalizeValidationText={i18next.t('CookieNotice.Buttons.Validation')}
                acceptButtonText={i18next.t('CookieNotice.Buttons.Accept')}
                declineButtonText={i18next.t('CookieNotice.Buttons.Decline')}
                personalizeButtonText={i18next.t('CookieNotice.Buttons.Personalize')}
                // personalizeButtonText={'I want to choose my cookies !'}
                cookies={[
                  {
                    name: 'necessary',
                    editable: false,
                    default: true,
                    title: i18next.t('CookieNotice.Essentiel'),
                    text: i18next.t('CookieNotice.EssentielDescription'),
                  },
                  {
                    name: 'gatsby-gdpr-google-tagmanager',
                    editable: true,
                    default: true,
                    title: i18next.t('CookieNotice.GTM'),
                    text: i18next.t('CookieNotice.GTMDescription'),
                  },
                ]}
              >
                <>
                  <h4>{i18next.t('CookieNotice.Title')}</h4>
                  <p>{i18next.t('CookieNotice.Description')}</p>
                </>
              </CookieNotice>
            </HubspotProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </I18nextProvider>
    </>
  )
}
