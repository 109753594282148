import React from 'react'
import Box from '@mui/material/Box'
import { Contacts } from './Contacts'
// import { FooterNavigation } from './FooterNavigation'

export const FooterTop: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column-reverse', sm: 'row' },
        justifyContent: { xs: 'flex-start', sm: 'space-between' },
        rowGap: '1.5em',
      }}
    >
      <Contacts />
      {/* <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'end' }}>
        <FooterNavigation expandAlways />
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' }, width: 1 }}>
        <FooterNavigation />
      </Box> */}
    </Box>
  )
}
