import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectProps } from '@mui/material/Select'
import MuiLink from '@mui/material/Link'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, WindowLocation } from '@reach/router'
import { useSiteMetadata } from '../../../helpers/useSiteMetadata'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import { useLocalizationStore } from '../../../stores/localization'
import { palette } from '../../../constants/themeVars'
import { languagesI18n } from '../../../constants/langConstants'

interface Props {
  hideonMobile: boolean
  onChangeLanguage: VoidFunction
}

export const LangSwitcher: React.FC<Props> = ({ hideonMobile, onChangeLanguage }) => {
  const specialPages = useLocalizationStore((store) => store.pages)

  const getLangHref = (targetLanguage: languagesI18n | 'ru' | 'uk', location: WindowLocation<unknown>) => {
    if (targetLanguage === 'ru') {
      return 'https://www.gostudy.cz'
    }

    if (targetLanguage === 'uk') {
      return 'https://www.gostudy.cz/uk'
    }

    if (specialPages[location.pathname.slice(3)] && !specialPages[location.pathname.slice(3)].includes(targetLanguage)) {
      return location.pathname.replace(location.pathname.slice(0), `/${targetLanguage}`)
    }

    return location.pathname.replace(location.pathname.slice(0, 3), `/${targetLanguage}`)
  }

  const [isOpen, setIsOpen] = useState(false)

  const loc = useLocation()
  const { t, i18n } = useTranslation()
  const { supportedLanguages } = useSiteMetadata()

  const czLanguages = ['ru', 'uk']

  const curLang = i18n.language
  const selectProps: SelectProps<unknown> = hideonMobile
    ? {
        disableUnderline: true,
        variant: 'standard',
        sx: {
          display: { xs: 'none', sm: 'flex' },
          color: 'primary.contrastText',
          marginRight: '.625em',
          '& > div > a': { color: { xs: 'text.secondary', sm: 'primary.contrastText' }, paddingLeft: '0' },
          // '& a': { paddingLeft: '0' },
        },
      }
    : {
        fullWidth: true,
        variant: 'outlined',
        sx: {
          display: { xs: 'flex', sm: 'none' },
          height: '2.5em',
          color: 'primary.contrastText',
          paddingRight: '1em',
          '& > div > a': { color: { xs: 'text.secondary', sm: 'primary.contrastText' }, paddingLeft: '0' },
          // '& a': { paddingLeft: '0' },
        },
      }
  return (
    <Box position={'relative'} sx={{ width: { xs: '100%', sm: 'initial' } }}>
      <Select
        {...selectProps}
        IconComponent={(props) => (
          <KeyboardArrowDownRounded
            {...props}
            sx={{
              color: { xs: 'text.secondary', sm: 'primary.contrastText' },
              fontSize: '1.5em',
              right: { xs: '.625em', sm: '10px' },
            }}
          />
        )}
        open={isOpen}
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        MenuProps={{
          keepMounted: true,
          disablePortal: true,
          sx: {
            '& .MuiPaper-root': {
              marginTop: { xs: '0', sm: '.5em' },
              background: palette.primary?.main,
              color: palette.primary?.contrastText,
              padding: '1rem 0',
            },
            '& .MuiList-root': { padding: '0' },
            '& .MuiButtonBase-root': {
              padding: '0.5em 1.25em',
              '&:hover': { background: palette.secondary?.states?.focus },
            },
          },
        }}
        value={curLang}
        onChange={(e) => {
          onChangeLanguage()
          // navigate(getLangHref(e.target.value as languagesI18n, loc))
          setIsOpen(false)
        }}
      >
        {[...supportedLanguages, ...czLanguages].map((lang: languagesI18n | 'ru' | 'uk', index: number) => (
          <MenuItem
            key={index}
            value={lang}
            sx={{ height: { xs: '2.5em', sm: 'auto' }, color: 'white', padding: '0 !important' }}
          >
            <MuiLink
              href={getLangHref(lang, loc)}
              onClick={() => {
                setIsOpen(false)
                onChangeLanguage()
              }}
              sx={{
                color: 'primary.contrastText',
                textDecoration: 'none',
                padding: '0.5em 1.25em',
                width: '100%',
              }}
            >
              {t(`LanguageSwitcher.${lang}`)}
            </MuiLink>
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
