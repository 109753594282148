export const phoneNumber = '+420 296 184 033'

export const addresses = {
  // prague: {
  //   title: 'Prague office',
  //   data: 'Washingtonova 1567/25, Prague',
  // },
  // arabEmirates: {
  //   title: 'Our office in Dubai',
  //   data: 'Malak Muhammad Amin Mirza Ghafari-Deira-Abu Hail, Office No 202-OF, United Arab Emirates',
  // },
}

export const socials = {
  facebook: 'https://www.facebook.com/WeAreGoStudy',
  instagram: 'https://www.instagram.com/gostudy.world/',
  // linkedin: 'https://www.linkedin.com/company/gostudy-eu/',
  // twitter: 'https://twitter.com/go_study',
  youtube: 'https://www.youtube.com/@GoStudyWorld',
}

export const email = 'info@gostudy.eu'

export const pageSize = 12
export const pageSizeProgramms = 8
