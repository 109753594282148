import { Link as NavLink } from 'gatsby'
import LinkMui from '@mui/material/Link'
import React, { Ref, forwardRef } from 'react'
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx'
import { navigateWithoutCache } from '../helpers/URLSearchParamsHelper'
import { useClientPagination } from '../stores/clientPagination'

type Props = { to: string; withoutCache?: boolean; sx?: SxProps; onClick?: (to: string) => void; target?: string }

export const Link = forwardRef((props: React.PropsWithChildren<Props>, ref: Ref<NavLink<unknown>>) => {
  const { onClick, ...restProps } = props
  const { setCurrentClientPage, setIsInitLoad } = useClientPagination((state) => ({
    setCurrentClientPage: state.setCurrentClientPage,
    setIsInitLoad: state.setIsInitLoad,
  }))

  return props.withoutCache ? (
    <LinkMui
      component={NavLink}
      {...restProps}
      ref={ref}
      onClick={(e) => {
        e.stopPropagation()
        onClick && onClick(props.to)
      }}
    >
      {props.children}
    </LinkMui>
  ) : (
    <LinkMui
      {...restProps}
      // ref={ref}
      onClick={(e) => {
        e.preventDefault
        e.stopPropagation()
        setIsInitLoad(true)
        setCurrentClientPage(1)
        onClick && onClick(props.to)
        navigateWithoutCache(props.to)
      }}
    >
      {props.children}
    </LinkMui>
  )
})

Link.displayName = 'Link'
