import React, { useRef, useState } from 'react'
import { NavigationDrawer } from './NavigationDrawer'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import EuroUnion from '../../../images/svg/euroUnion.svg'
import Czech from '../../../images/svg/czech.svg'
import Slovakia from '../../../images/svg/Slovakia.svg'
import Austria from '../../../images/svg/Austria.svg'
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded, LocalLibraryRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import { pagelist } from '../../../../pagelist.data'
import Eu from '../../../images/eu.png'
import Cz from '../../../images/cz.png'
import Sl from '../../../images/sl.png'
import Au from '../../../images/au.png'

export const EducationalCenter: React.FC<{ onLinkClick?: () => void }> = ({ onLinkClick }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(Boolean(anchorEl))
  const onLinkClickRef = useRef<VoidFunction>()
  const data = [
    {
      avatar: <EuroUnion />,
      oldSafariImg: Eu,
      title: t('EducationalCentre.Column1.Title'),
      posts: [
        {
          to: `/${lang}/media/knowledge/education/the-european-educational-system`,
          title: t('EducationalCentre.Column1.Item1.Title'),
          description: t('EducationalCentre.Column1.Item1.Description'),
        },
        {
          to: `/${lang}/media/knowledge/education/5-ways-to-study-in-europe-for-free`,
          title: t('EducationalCentre.Column1.Item2.Title'),
          description: t('EducationalCentre.Column1.Item2.Description'),
        },
        {
          to: `/${lang}${pagelist.universities.path}`,
          title: t('EducationalCentre.Column1.Item3.Title'),
          description: t('EducationalCentre.Column1.Item3.Description'),
        },
      ],
    },
    {
      avatar: <Czech />,
      oldSafariImg: Cz,
      title: t('EducationalCentre.Column2.Title'),
      posts: [
        {
          to: `/${lang}/media/null/blog/15-facts-about-the-czech-republic`,
          title: t('EducationalCentre.Column2.Item1.Title'),
          description: t('EducationalCentre.Column2.Item1.Description'),
        },
        {
          to: `/${lang}${pagelist.czechRepublicHigherEducation.path}`,
          title: t('EducationalCentre.Column2.Item2.Title'),
          description: t('EducationalCentre.Column2.Item2.Description'),
        },
        // {
        //   to: `/${lang}/media/knowledge/education/how-to-go-as-an-exchange-student-from-the-czech-republic`,
        //   title: t('EducationalCentre.Column2.Item3.Title'),
        //   description: t('EducationalCentre.Column2.Item3.Description'),
        // },
        {
          // to:
          //   lang === 'en'
          //     ? `/${lang}${pagelist.universityProgram.path}`
          //     : `/${lang}/media/knowledge/education/how-to-go-as-an-exchange-student-from-the-czech-republic`,
          // title: lang === 'en' ? t('EducationalCentre.Column2.Item3.En.Title') : t('EducationalCentre.Column2.Item3.Title'),
          // description:
          //   lang === 'en'
          //     ? t('EducationalCentre.Column2.Item3.En.Description')
          //     : t('EducationalCentre.Column2.Item3.Description'),
          to: `/en${pagelist.universityProgram.path}`,
          title: t('EducationalCentre.Column2.Item3.En.Title'),
          description: t('EducationalCentre.Column2.Item3.En.Description'),
        },
        {
          to: `/${lang}/media/null/blog/all-about-bachelor-s-programmes-and-studying-for-a-bachelor-s-degree-in-the-czech-republic`,
          title: t('EducationalCentre.Column2.Item4.Title'),
          description: t('EducationalCentre.Column2.Item4.Description'),
        },
        {
          to: `/${lang}/media/knowledge/education/master-s-degree-in-the-czech-republic`,
          title: t('EducationalCentre.Column2.Item5.Title'),
          description: t('EducationalCentre.Column2.Item5.Description'),
        },
        {
          to: `/${lang}/media/knowledge/education/how-to-be-admitted-to-the-post-doctoral-program-in-europe`,
          title: t('EducationalCentre.Column2.Item6.Title'),
          description: t('EducationalCentre.Column2.Item6.Description'),
        },
        {
          to: `/${lang}${pagelist.czechLanguageVisa.path}`,
          title: t('EducationalCentre.Column2.Item7.Title'),
          description: t('EducationalCentre.Column2.Item7.Description'),
        },
      ],
    },
    {
      avatar: <Slovakia />,
      oldSafariImg: Sl,
      title: t('EducationalCentre.Column3.Title'),
      posts: [
        {
          to: `/${lang}/media/null/blog/everything-about-life-in-slovakia`,
          title: t('EducationalCentre.Column3.Item1.Title'),
          description: t('EducationalCentre.Column3.Item1.Description'),
        },
        {
          to: `/${lang}/media/knowledge/education/undergraduate-and-master-s-programs-in-the-czech-republic-or-slovakia-in-english`,
          title: t('EducationalCentre.Column3.Item2.Title'),
          description: t('EducationalCentre.Column3.Item2.Description'),
        },
        {
          to: `/${lang}/media/null/blog/how-to-learn-slovak-language-online-and-move-to-slovakia-in-a-year`,
          title: t('EducationalCentre.Column3.Item3.Title'),
          description: t('EducationalCentre.Column3.Item3.Description'),
        },
      ],
    },
    {
      avatar: <Austria />,
      oldSafariImg: Au,
      title: t('EducationalCentre.Column4.Title'),
      posts: [
        {
          to: `/${lang}${pagelist.austriaHigherEducation.path}`,
          title: t('EducationalCentre.Column4.Item1.Title'),
          description: t('EducationalCentre.Column4.Item1.Description'),
        },
        {
          to: `/${lang}/media/knowledge/education/master-s-programme-in-austria`,
          // to: 'https://www.gostudy.cz/avstriya/obrazovanie-v-avstrii/magistratura-v-avstrii',
          title: t('EducationalCentre.Column4.Item2.Title'),
          description: t('EducationalCentre.Column4.Item2.Description'),
        },
        {
          to: `/${lang}${pagelist.austriaPersonalAssistant.path}`,
          title: t('EducationalCentre.Column4.Item3.Title'),
          description: t('EducationalCentre.Column4.Item3.Description'),
        },
      ],
    },
  ]

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen((prev) => !prev)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // const checkIsOldSafari = () => {
  //   if (typeof navigator === 'undefined' || !navigator?.userAgent) return false
  //   if (navigator.userAgent.includes('Version/15') && navigator.userAgent.includes('Safari')) {
  //     return true
  //   }
  //   return false
  // }

  const content = () => (
    <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: '.75em', sm: '1em' }}>
      {data.map((item, index) => {
        return (
          <>
            <Stack key={item.title} padding={{ xs: '0 0 .5em', sm: '.5em 0' }} gap={'.75em'}>
              <Stack direction={'row'} alignItems={'center'} gap={'.625em'} px={'1em'}>
                <Box component={'img'} src={item.oldSafariImg} alt={item.title} width={'2rem'} height={'2rem'}></Box>
                {/* FYI: drawers keepMounted flag ruin svg render on old safari and mobile (flag is important for seo) */}
                {/* {checkIsOldSafari() ? (
                  <Box component={'img'} src={item.oldSafariImg} alt={item.title} width={'2rem'} height={'2rem'}></Box>
                ) : (
                  item.avatar
                )} */}
                <Typography variant='subtitle1'>{item.title}</Typography>
              </Stack>
              <Stack paddingTop={'.5em'} gap={'1em'}>
                {item.posts.map((post) => {
                  return (
                    <Link
                      key={post.title}
                      to={post.to}
                      style={{ color: 'inherit', textDecoration: 'none' }}
                      onClick={() => {
                        if (onLinkClickRef.current) {
                          onLinkClickRef.current()
                        }
                        if (onLinkClick) {
                          onLinkClick()
                        }
                      }}
                    >
                      <Stack
                        key={post.title}
                        px={'1em'}
                        sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }, cursor: 'pointer' }}
                      >
                        <Typography variant='body1'>{post.title}</Typography>
                        <Typography variant='caption' color={'text.secondary'}>
                          {post.description}
                        </Typography>
                      </Stack>
                    </Link>
                  )
                })}
              </Stack>
            </Stack>
            {index !== data.length - 1 && <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />}
          </>
        )
      })}
    </Stack>
  )

  return (
    <>
      <Button
        size='large'
        sx={{
          display: { xs: 'none', sm: 'flex' },
          justifyContent: 'space-between',
          py: '.5rem',
          px: '.6875rem',
          '&:hover svg': { color: 'inherit' },
        }}
        onClick={handleClick}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <Box sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', columnGap: '.5em' }}>
          <LocalLibraryRounded
            sx={{
              color: 'primary.states.outlinedBorder',
              width: '1.25em',
              transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
            }}
          />
          <Typography variant={'subtitle2'} component={'span'} sx={{ color: theme.palette.text.primary }}>
            {t('Header.EducationalCenter')}
          </Typography>
        </Box>
        <Menu
          keepMounted
          // disablePortal={true}
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .MuiPaper-root': {
              width: `${theme.breakpoints.values.md}px`,
              left: '50% !important',
              transform: 'translate(-50%, 0) !important',
              marginTop: '1.5rem',
            },
            '& .MuiList-root': { padding: '1em' },
          }}
        >
          {content()}
        </Menu>
        {open ? (
          <KeyboardArrowUpRounded
            sx={{
              width: '1.25rem',
              height: '1.25rem',
              display: { xs: 'none', sm: 'flex' },
              color: 'text.primary',
              marginLeft: '.375rem',
            }}
          />
        ) : (
          <KeyboardArrowDownRounded
            sx={{
              width: '1.25rem',
              height: '1.25rem',
              display: { xs: 'none', sm: 'flex' },
              color: 'text.primary',
              marginLeft: '.375rem',
            }}
          />
        )}
      </Button>
      <NavigationDrawer
        onLinkClickRef={onLinkClickRef}
        buttonText='Header.EducationalCenter'
        content={content()}
        icon={
          <LocalLibraryRounded
            sx={{
              color: 'primary.states.outlinedBorder',
              width: '1.25em',
              transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
            }}
          />
        }
      />
    </>
  )
}
