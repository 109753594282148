exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-templates-about-us-for-partners-tsx": () => import("./../../../src/templates/about-us-for-partners.tsx" /* webpackChunkName: "component---src-templates-about-us-for-partners-tsx" */),
  "component---src-templates-about-us-new-tsx": () => import("./../../../src/templates/about-us-new.tsx" /* webpackChunkName: "component---src-templates-about-us-new-tsx" */),
  "component---src-templates-about-us-offices-tsx": () => import("./../../../src/templates/about-us-offices.tsx" /* webpackChunkName: "component---src-templates-about-us-offices-tsx" */),
  "component---src-templates-about-us-reviews-tsx": () => import("./../../../src/templates/about-us-reviews.tsx" /* webpackChunkName: "component---src-templates-about-us-reviews-tsx" */),
  "component---src-templates-academy-tsx": () => import("./../../../src/templates/academy.tsx" /* webpackChunkName: "component---src-templates-academy-tsx" */),
  "component---src-templates-austria-higher-education-tsx": () => import("./../../../src/templates/austria-higher-education.tsx" /* webpackChunkName: "component---src-templates-austria-higher-education-tsx" */),
  "component---src-templates-austria-personal-assistant-tsx": () => import("./../../../src/templates/austria-personal-assistant.tsx" /* webpackChunkName: "component---src-templates-austria-personal-assistant-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-czech-in-english-tsx": () => import("./../../../src/templates/czech-in-english.tsx" /* webpackChunkName: "component---src-templates-czech-in-english-tsx" */),
  "component---src-templates-czech-language-visa-tsx": () => import("./../../../src/templates/czech-language-visa.tsx" /* webpackChunkName: "component---src-templates-czech-language-visa-tsx" */),
  "component---src-templates-czech-republic-doctors-tsx": () => import("./../../../src/templates/czech-republic-doctors.tsx" /* webpackChunkName: "component---src-templates-czech-republic-doctors-tsx" */),
  "component---src-templates-czech-republic-higher-education-tsx": () => import("./../../../src/templates/czech-republic-higher-education.tsx" /* webpackChunkName: "component---src-templates-czech-republic-higher-education-tsx" */),
  "component---src-templates-czech-republic-holiday-tsx": () => import("./../../../src/templates/czech-republic-holiday.tsx" /* webpackChunkName: "component---src-templates-czech-republic-holiday-tsx" */),
  "component---src-templates-czech-republic-study-online-tsx": () => import("./../../../src/templates/czech-republic-study-online.tsx" /* webpackChunkName: "component---src-templates-czech-republic-study-online-tsx" */),
  "component---src-templates-main-tsx": () => import("./../../../src/templates/main.tsx" /* webpackChunkName: "component---src-templates-main-tsx" */),
  "component---src-templates-media-tsx": () => import("./../../../src/templates/media.tsx" /* webpackChunkName: "component---src-templates-media-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-program-tsx": () => import("./../../../src/templates/program.tsx" /* webpackChunkName: "component---src-templates-program-tsx" */),
  "component---src-templates-programs-tsx": () => import("./../../../src/templates/programs.tsx" /* webpackChunkName: "component---src-templates-programs-tsx" */),
  "component---src-templates-typeform-tsx": () => import("./../../../src/templates/typeform.tsx" /* webpackChunkName: "component---src-templates-typeform-tsx" */),
  "component---src-templates-universities-tsx": () => import("./../../../src/templates/universities.tsx" /* webpackChunkName: "component---src-templates-universities-tsx" */),
  "component---src-templates-university-program-tsx": () => import("./../../../src/templates/university-program.tsx" /* webpackChunkName: "component---src-templates-university-program-tsx" */),
  "component---src-templates-university-tsx": () => import("./../../../src/templates/university.tsx" /* webpackChunkName: "component---src-templates-university-tsx" */)
}

